
import MenuItem from '@/modules/common/components/sidebar-menu-item.vue';
import FEATURES_ICONS from '@/modules/common/constants/features-icons.constant';
import FEATURES from '@/modules/common/constants/features.constant';
import UserNavigationService, { UserNavigationServiceS } from '@/modules/user/user-navigation.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';
import { TranslateResult } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';
import getParentNonModalRoute from '../filters/no-modal-route.filter';

interface SidebarItem {
    to: string;
    isActive: boolean;
    iconClass: string;
    isLocked: boolean;
    title: TranslateResult;
    isNeedHide: boolean | null;
}

@Component({
    components: {
        Item: MenuItem,
    },
})
export default class Sidebar extends Vue {
    @Inject(UserServiceS) userService!: UserService;
    @Inject(UserNavigationServiceS) userNavigationService!: UserNavigationService;

    get routes() {
        return this.userNavigationService.routes;
    }

    get isNewUser() {
        return this.userService.isNewUser;
    }

    get isMenuBlocked() {
        return this.isNewUser;
    }

    get isModal() {
        return this.$route.meta?.modal;
    }

    get menuItems() {
        // NOTE: Add features here if you need to hide it in sidebar.
        const disabledFeatures = [] as FEATURES[];
        const disabledOnChain = [] as FEATURES[];
        const disabledOnCluster = [FEATURES.HOME] as FEATURES[];

        const menuItems = $enum(FEATURES)
            .map((feature: FEATURES) => {
                const { viewAs } = this.userService;

                let actualFeature = `${viewAs}.${feature}`;
                let featureName = '';

                switch (feature) {
                    case 'home':
                        actualFeature = `${viewAs}.home`;
                        featureName = this.$tc('titles.home');
                        break;

                    case 'rate':
                        featureName = this.$tc('titles.rates');
                        break;

                    case 'market':
                        featureName = this.$tc('titles.markets');
                        break;

                    case 'events':
                        actualFeature = `${viewAs}.events-manager`;
                        featureName = this.$tc('titles.eventsManager');
                        break;

                    case 'guest_review':
                        actualFeature = actualFeature.replace(feature, 'ranking');
                        featureName = this.$tc('titles.ranking');
                        break;

                    case 'lite_di':
                        actualFeature = actualFeature.replace(feature, 'di-lite');
                        featureName = this.$tc('titles.dilite');
                        break;

                    case 'promotion_detection':
                        actualFeature = actualFeature.replace(feature, 'promotions');
                        featureName = this.$tc('titles.promotions');
                        break;

                    case 'deep_compset':
                        actualFeature = actualFeature.replace(feature, 'compset-benchmark');
                        featureName = this.$tc('titles.deepCompset');
                        break;

                    case 'targeted_insights':
                        actualFeature = actualFeature.replace(feature, 'insights');
                        featureName = this.$tc('titles.insights');
                        break;

                    default:
                        break;
                }

                const isLocked = this.isFeatureLocked(feature);
                const isFeatureExists = this.userService.enabledFeatures![feature] !== undefined;
                const isDisabledOnChain = disabledOnChain.includes(feature) && this.userService.isViewAsChain;
                const isDisabledOnCluster = disabledOnCluster.includes(feature) && this.userService.isViewAsCluster;
                const isDisabledFeature = disabledFeatures.includes(feature);
                const routeName = this.$route.name || '';

                return {
                    to: this.getRoute(this.routes[feature], isLocked),
                    isActive: routeName.startsWith(actualFeature),
                    iconClass: FEATURES_ICONS[feature],
                    isLocked,
                    title: featureName,
                    isNeedHide: !isFeatureExists || isDisabledFeature || isDisabledOnCluster || isDisabledOnChain,
                };
            })
            .filter(r => !!r) as SidebarItem[];

        return menuItems.filter(item => !item.isNeedHide);
    }

    get isDeepCompsetEnabled() {
        return this.userService.enabledFeatures![FEATURES.DEEP_COMPSET];
    }

    getRoute(to: string, isLocked: boolean) {
        const { name } = this.$route;

        if (isLocked && name && !name.includes('.locked')) {
            const actualRouteName = getParentNonModalRoute(this.$router, this.$route);

            const route = this.$router.resolve({ name: `${actualRouteName}.locked` });
            return route.href;
        }

        return to;
    }

    isFeatureLocked(feature: FEATURES) {
        if (!this.userService.enabledFeatures) {
            return false;
        }
        return !this.userService.enabledFeatures[feature];
    }
}
